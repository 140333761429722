/* Logo styling to fix it at the top-left corner */
.logo-top-left {
  position: absolute; /* Fixes the logo container in the corner */
  top: 20px;
  left: 20px;
}

.logo {
  width: 200px; /* Adjust logo size if needed */
  position: fixed;
  top: 0;
  left: 0;
}

/* Center the header and add spacing from the top */
header {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px; /* Adjust to push header down from the top */
}

header h1 {
  font-size: 2.5rem;
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
}

header p {
  font-size: 1.2rem;
  margin-top: 5px;
  font-family: 'Courier New', Courier, monospace;
}

/* Centering the sections below the header */
.previous-pastes-section {
  text-align: center;
  margin-bottom: 100px; /* Increase space below this section */
  
}

.create-paste-section {
  text-align: center;
  margin-bottom: 40px; /* Ensure consistent spacing from the previous section */
}

/* Additional styling for lists */
ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
}


.previous-pastes-section h2 {
  font-family: 'Courier New', Courier, monospace;
}


.create-paste-section h2 {
  font-family: 'Courier New', Courier, monospace;
}