
.paste-view {
  padding: 20px;
  position: relative; /* Position relative for absolute logo positioning */
  max-width: 800px;
  margin: 0 auto;
}

.logo {
  width: 150px; 
  height: auto;
}

/* Centering the header */
.paste-header {
  text-align: center;
  margin-top: 100px; 
}

.paste-header h1 {
  font-size: 2rem;
  margin: 0;
}

/* Wrapper for content and the button */
.paste-content-container {
  display: flex;
  justify-content: space-between; /* Space between language info and copy button */
  align-items: center;
  margin-top: 20px;
}

/* Style for language info */
.language-info {
  font-size: 1.2rem;
  font-family: Marker felt, fantasy;
}


.copy-button {
  background-color: #007bff; /* Example color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.copy-button:hover {
  background-color: #0056b3; /* Darker color on hover */
}

/* Styling for the code container */
.paste-content-box {
  margin-top: 20px;
}

/* Ensure the code container is properly styled */
.paste-content-box pre {
  margin: 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  overflow-x: auto; /* Allow horizontal scrolling */
}
