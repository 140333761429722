
/* General container styles to center content */
.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    padding: 20px;
  }
  
  .auth-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .auth-header h2 {
    font-size: 2rem;
    margin: 0;
  }
  
  .auth-form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .auth-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .auth-form button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .auth-form button:hover {
    background-color: #45a049;
  }
  
  .auth-footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .auth-footer a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .auth-footer a:hover {
    text-decoration: underline;
  }
  