.App {
  text-align: center;
  padding: 20px;
}

h1, h2 {
  font-family: Arial, sans-serif;
}

button {
  padding: 10px 20px;
  margin: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #45a049;
}

input, select, textarea {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 80%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 200px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}

ul li a {
  color: #007BFF;
  text-decoration: none;
}

ul li a:hover {
  text-decoration: underline;
}
